.worker-profile {
  display: flex;
  flex-direction: column;

  .worker-profile__image {
    display: flex;
    justify-content: center;

    img {
      width: 16.875rem;
      height: 16.875rem;
    }

    .editImage {
      position: absolute;
      top: 25px;
      right: 20px;
    }
  }

  .worker-profile__qr {
    padding: 0.5rem;
    align-self: center;
    position: relative;
    margin-top: -3.125rem;
    background: white;
    cursor: pointer;
  }

  .worker-profile__text {
    text-align: center;

    &.light {
      color: $grey-59;
    }
  }

  .worker-profile__status {
    margin: 1.25rem 0rem;
    background: $status-orange-96;
    height: 3.875rem;
    position: relative;

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      label {
        font-weight: bold;
        color: $status-orange-69;
        margin: 0rem;
      }

      span {
        font-size: 0.875rem;
        color: $grey-59;
        margin: 0rem;
      }
    }

    &.available {
      background: $status-green-96;

      section {
        label {
          color: $status-green-59;
        }
      }
    }

    .action {
      position: absolute;
      right: 0.625rem;
      height: 100%;
      display: flex;
      align-items: center;
      top: 0;
    }
  }

  section {
    display: flex;
    flex-direction: column;

    h6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    span {
      font-size: 0.875rem;
      margin-bottom: 0.5rem;

      svg {
        font-size: 1rem;
        color: $primary;
      }
    }
  }
}
