$input-padding-x: 9px;
$input-padding-y: 10px;
$caret-margin: 20px;

.dropdown-toggle.btn-outline-light {
  padding: $input-padding-y ($input-padding-x + $caret-margin) $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  text-align: left;

  & .placeholder {
    font-weight: normal;
    color: $grey-59;
    pointer-events: none;
  }

  &::after {
    position: absolute;
    right: $input-padding-x;
    top: $input-padding-y;
    border: none;
    content: url("../assets/icons/Arrow\ Simple/Down.svg");
  }

  &:hover {
    &::after {
      content: url("../assets/icons/Arrow\ Simple/Down.svg");
    }
  }

  &.active,
  &:active,
  &[aria-expanded="true"] {
    border-color: red;
    border-width: 2px;
    padding: ($input-padding-y - 1px) ($input-padding-x + $caret-margin - 1px) ($input-padding-y - 1px) ($input-padding-x - 1px);
    background: white;

    &::after {
      content: url("../assets/icons/Arrow\ Simple/Down.svg");
      top: $input-padding-y + 4px;
      transform: scaleY(-1);
    }
  }

  &:disabled,
  &.disabled {
    color: $input-color;
  }
}

.dropdown-menu {
  // padding: $dropdown-padding-y * 2 $dropdown-padding-x $dropdown-padding-y;
  box-shadow: $dropdown-box-shadow;
  border: none;
}

.dropdown-item {
  font-weight: 600;
  border-radius: $border-radius;

  & + & {
    margin-top: 2px;
  }

  .icon {
    margin: 0 1rem 4px 0;
  }

  &:not(:hover):not(:active):not(.active) .icon {
    color: $grey-75;
  }
}
