.#{$prefix}uploader {
  border: 1px dashed $gray-300;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: $font-size-sm;

  & .icon {
    color: $primary;
  }

  div:focus {
    outline: none;
  }
}
