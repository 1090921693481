.success-link-sent {
  background: white;
  padding: 40px;

  .card {
    &__hero {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 120px;
      height: 120px;
      background: #e3f7fa;
      border-radius: 50%;
    }

    &__hero-icon {
      width: 100px;
      height: 100px;
    }

    &__btn {
      max-width: 120px;
    }
  }

  .optional-text {
    color: #dce0e8;
  }

  .description {
    &__identity {
      margin-left: 5px;
    }
  }
}
