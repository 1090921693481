@import "assets/themes/helpers";

.metrics {
  &__input {
    width: 70%;
  }

  &__tabs {
    margin-left: 40%;
  }

  &__table {
    .auto-answer {
      margin-bottom: 2.5rem;
      width: 60%;
      text-align: center;
    }
    & > td {
      vertical-align: middle !important;
    }
  }
  &__question {
    &-osha {
      width: 40%;
    }
  }
  &__header {
    width: 100%;
    align-items: baseline;
  }

  &__footer {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);

    &-wide {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    }
  }

  &__switch {
    display: flex;

    & .btn-dark {
      max-height: 2.8rem;
      background: $grey-96;
      border-color: $grey-96;
      color: $grey-47;
    }
  }
}
