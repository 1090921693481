@import "assets/themes/helpers";

.loading-container {
  position: relative;
}

.loading-container__spinner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  > .spinner-border {
    margin: 5rem 0;
  }

  background: rgba(241, 244, 245, 0.5);
}