

.metrics__table {
  td {
    padding: 0.625rem 0.25rem;
    input {
      width: 100%;
      padding: 0.625rem 0.25rem;
    }
  }
}
