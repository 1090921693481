.modal-dialog {
  .modal-content {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    border: 0px solid transparent;

    .modal-header {
      padding: 1.25rem 1.5625rem;
    }

    .modal-body {
      padding: 0 3.125rem;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .modal-footer {
      padding: 1.875rem;
    }
  }
}
