@import "assets/themes/helpers";

.worker-filter {
  margin-bottom: 2rem;

  h5 {
    padding: 1rem;
    text-transform: uppercase;
  }

  .filter-item {
    padding: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-89;
    }
  }
}
