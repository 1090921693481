@import "./rounded";

.btn {
  &:focus {
    opacity: 1;
    box-shadow: none;
    outline: none;
  }

  &.btn-circle {
    border-radius: 50%;
    font-size: 0;
    width: 40px;
    height: 40px;

    background-color: $white;
    border-color: $grey-89;

    &:hover {
      &:not(:disabled):not(.disabled) {
        background-color: $white;
        border-color: $grey-80;
      }
    }

    &:disabled {
      background-color: $white;
      border-color: $grey-89;
    }
  }

  &.btn-primary:hover {
    box-shadow: none;
    background-color: $green-47;
  }

  &.btn-primary:disabled {
    background-color: $green-89;
    border-color: $green-89;
  }

  &.btn-light {
    background-color: $grey-96;
    color: $green-7;

    &:hover {
      &:not(:disabled):not(.disabled) {
        background-color: $white;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
        border-color: $white;
      }
    }

    &:disabled {
      color: $grey-80;
    }
  }

  &.btn-link {
    color: $green-7;

    &:hover {
      &:not(:disabled):not(.disabled) {
        color: $primary;
        text-decoration: none;
      }
    }

    &:disabled {
      color: $grey-80;
    }
  }

  &.btn-outline-primary {
    border-color: $grey-89;
    color: $primary;
    background: transparent;

    &:disabled {
      border-color: $grey-89;
      color: $green-89;
      opacity: 1;
    }

    &:hover {
      &:not(:disabled):not(.disabled) {
        color: $green-47;
        border-color: $grey-80;
        opacity: 1;
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

.btn-success {
  color: $status-green-59 !important;
}
