.nav-tabs {
  .nav-link {
    color: $grey-47;
    border: 0px;
    padding: 8px 0px;

    &:hover {
      color: $grey-35;
      border: 0px;
      border-bottom: 1px solid $grey-35;
    }

    &.active {
      border: none;
      border-bottom: 2px solid $primary;
      padding: 8px 0px;
      &:hover {
        color: $primary;
      }
    }
  }

  .nav-item {
    margin: 0px 8px;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}
