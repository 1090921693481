.navbar {
  align-items: stretch;
  margin-bottom: 30px;
}
.navbar-light {
  background: white;
}

.navbar-brand {
  padding: 1rem;
}

#basic-navbar-nav {
  align-items: stretch;

  .navbar-nav {
    flex-grow: 1;
    justify-content: center;

    font-family: $headings-font-family;
    font-size: 1rem;
    letter-spacing: 0.05em;
    color: $grey-35;
  }

  .nav-link {
    margin: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;

    &.active {
      color: $primary;
      border-color: $primary;
    }
  }

  & > .btn-lg {
    height: 100%;
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 0;

    svg {
      font-size: 150%;
    }
  }
}
