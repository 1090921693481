.react-select__control {
  font-size: 0.875rem;
  &--is-focused {
    box-shadow: none !important;
  }
}

.react-select__menu {
  font-size: 0.875rem;
}
