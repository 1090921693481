@import "assets/themes/helpers";

.insurance {
  &__requirements {
    margin-top: 4rem;
  }
  &__title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &__link {
    display: block;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 70%;

    & > svg {
      flex: 0 0 30%;
      margin-bottom: 0.1rem;
    }
  }

  &__table {
    thead {
      tr {
        border-bottom: 1px solid $grey-89;
      }
    }
    .action__row {
      &:not(:last-child) {
        border-bottom: 1px solid $grey-89;
      }

      td {
        padding: 0;
        div {
          padding-bottom: 0.75rem;
          display: flex;
          flex-wrap: wrap;
          * {
            flex: 1;
          }
        }
      }
    }
  }
}
