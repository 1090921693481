@import "assets/themes/helpers";

.filter-item {
  font-weight: bold;
  font-size: 1rem;
  color: $green-7;
  padding: 1rem 0;

  /* header */
  > :nth-child(1) {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
  
    font-weight: normal;
    font-size: 1rem;
    color: $grey-59;
    margin-bottom: 0.5rem;
  }

  /* content */
  > :nth-child(2) {
    > * {
      margin: 1rem 0;
    }
  }
}
