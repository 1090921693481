form {
  width: 100%;
}

.form-control,
.custom-select {
  line-height: 0.9375rem;
  font-size: 0.875rem;

  &:not(:disabled) {
    font-weight: bold;
  }

  &::placeholder {
    font-weight: normal;
    line-height: 1.063rem;
    font-size: 0.875rem;
    color: $grey-69;
  }

  &:hover:not(.is-invalid):not(:focus):not(:disabled) {
    border-color: $grey-75;
  }

  &:disabled {
    background-color: transparent;
    border-color: $grey-89;

    &::placeholder {
      color: $grey-89;
    }
  }
}

.form-group {
  .form-control {
  }

  .form-label {
    line-height: 1.063rem;
    font-size: 0.875rem;
    color: $green-7;
  }

  &.disabled {
    .form-label {
      color: $grey-89;
    }
  }
}

input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
select.custom-select:focus {
  box-shadow: none;
  border-color: $primary;
}
