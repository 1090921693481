@import "assets/themes/helpers.scss";

.company__header {
  min-height: 7rem;
  padding: 2rem 0;

  span {
    color: $grey-47;
    font-size: 0.75rem;
  }
}

.company__nav {
  display: flex;
  flex-direction: column;

  .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: $grey-35;
    padding: 0.875rem;
    border-radius: 2px;

    > svg {
      margin-right: 1rem;
    }

    &.active {
      background: $primary;
      color: white;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }
  }
}

.company__logo {
  min-height: 7rem;
  padding-top: 2rem;
  padding-bottom: 1rem;

  &-empty {
    font-size: 1.5rem;
    color: $primary;
    font-family: $headings-font-family;
    padding: 1.2rem;
  }

  img {
    width: 100%;
  }

  button {
    position: absolute;
    right: 0;
  }
}
