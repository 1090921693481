@import "assets/themes/helpers";

.attachment {
  margin: 1rem 1rem;
  padding: 0.7rem 0;
  align-items: center;
  position: relative;

  &__done {
    & .attachment__name,
    .attachment__icon,
    .attachment__delete {
      color: white;
    }
  }

  &__error {
    &-message {
      color: $status-red-47;
      margin-left: 1rem;
    }

    & .attachment__name,
    .attachment__icon,
    .attachment__delete {
      color: white;
    }
  }

  &__name {
    line-break: anywhere;
  }

  &__icon {
    width: 60%;
    height: 60%;
    color: $grey-59;
  }

  &__delete {
    color: $grey-59;
  }

  &__progress {
    width: 100%;
    position: absolute;
    bottom: 2px;
  }
}

.background-green-47 {
  background: $status-green-47;
}

.background-gray-96 {
  background: $grey-96;
}

.background-red-47 {
  background: $status-red-47;
}
