@import "assets/themes/helpers.scss";

.worker-portal__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;

  input {
    min-width: 20rem;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
    border: none;
  }

  .paging-info {
    margin-right: auto;
    color: $grey-47;
  }

  .dropdown {
    margin-bottom: 1rem;
  }
}

.worker-portal__list {
  padding-bottom: 3rem;

  .worker-search-card {
    margin-bottom: 2rem;
  }
}

.worker-search-card {
  padding: 1rem;

  &__header {
    display: flex;

    > div {
      flex-direction: column;
      margin: 0 1rem;
    }

    .worker-search-card__availability {
      margin-left: auto;
      text-align: end;
    }
  }

  &__body {
    padding: 1rem 0;
    > * {
      margin-bottom: 0.5rem !important;
    }
  }

  &__footer {
    display: flex;

    button {
      flex: 1;
      > svg {
        margin-right: 0.5rem;
      }
    }
  }

  /* todo: refactor to global typography (once I know typography) */
  h5 {
    margin: 0;
  }

  span {
    color: $grey-47;
    font-size: 0.875rem;
    font-weight: normal;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;

    > span {
      font-size: 1rem;
      font-weight: normal;
      color: black;
    }
  }
  /* end of refactor */
}

.worker-search-card__availability {
  font-size: 0.875rem;
  font-weight: normal;
  color: $grey-47;

  &:before {
    content: "\25CF";
    margin: 0 0.4rem;
    font-size: 0.875rem;
  }

  &.red {
    color: $status-red-47;
  }

  &.green {
    color: $status-green-59;
  }

  &.orange {
    color: $status-orange-59;
  }
}
