.pagination {
  .next,
  .prev,
  .first,
  .last {
    width: 36px;
    height: 36px;
    margin: 0px 2px;
    &.page-item {
      .page-link {
        background: transparent !important;
        border: 1px solid $grey-89;
        border-radius: 50%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border-color: $primary;
        }
      }
    }
  }

  .page-item {
    .page-link {
      background: transparent !important;
    }
  }
}
