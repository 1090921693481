@import "assets/themes/helpers.scss";

.grade {
  font-weight: bold;

  &.is-success {
    color: $status-green-75;
  }

  &.is-warning {
    color: $status-orange-75;
  }

  &.is-danger {
    color: $status-red-75;
  }
}
