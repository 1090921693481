@import "assets/themes/helpers.scss";

.messages {
  display: flex;
  justify-content: center;

  .messages__container {
    max-width: 50rem;
    width: 100%;
  }

  .messages__container h4 {
    padding: 2rem 0rem;
  }

  .messages__container .card {
    padding: 1.75rem 1.875rem;
  }

  .paginationBar {
    display: flex;
    justify-content: center;
  }
}

.messages__list {
  padding: 0px;
  margin: 0px;

  .new {
    &.media {
      &::before {
        background-color: $primary;
      }

      .media-body p {
        font-weight: 700;
      }
    }
  }

  .media {
    &::before {
      content: " ";
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      position: absolute;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey-89;
      margin-bottom: 1.5rem;
    }
  }

  .media .media-body label {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 1.25rem;
    font-weight: 700;

    .message__avatar {
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    a {
      color: $black;
      &:hover {
        color: $primary;
      }
    }

    span {
      color: $grey-47;
      font-size: 1rem;
    }
  }

  .media .media-body {
    margin-left: 0.5rem;
    p {
      margin-bottom: 1.25rem;
      &.hovered {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 3rem;
        overflow-wrap: break-word;
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
}

// reply_message

.detailMessageModal__footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.hasAlert {
    justify-content: space-between;
  }

  h6 {
    margin: 0px;
    padding: 0px;
  }
}

// notification

.notification {
  &__menu {
    & .dropdown-menu {
      width: 600px;
      padding: 20px 30px;

      @include media-breakpoint-down(md) {
        width: 100vw;
      }
    }

    & .dropdown-item {
      padding: 20px 0;
      &:nth-child(odd) {
        border-bottom: 1px solid $grey-89;
        border-top: 1px solid $grey-89;
      }

      &:first-child {
        border-top: none;
      }

      &:hover {
        color: $primary;
        background: none;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    h5 {
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -1rem;

      a {
        font-size: 0.875rem;
        width: fit-content;
        margin: 0;
        padding: 0;
      }
    }
  }
}
