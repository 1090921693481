// @import "assets/themes/helpers";

.history-list {
  min-height: 4.375rem;
}

.history-list__item {
  border-bottom: 1px solid $grey-89;
  margin-bottom: 1.25rem;

  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-areas:
    "status status status status"
    "item__title item__title item__title item__title"
    "item__date item__date item__date item__action"
    "item__description item__description item__description item__description";

  .status {
    grid-area: status;
    justify-self: end;
  }

  .item__title {
    grid-area: item__title;
    margin-bottom: 0.5rem;
    span {
      font-family: initial !important;
      font-weight: normal !important;
    }

    &.edit {
      cursor: pointer;
    }
  }

  .item__date {
    grid-area: item__date;
    color: $grey-59;
    margin-bottom: 1.25rem;
  }

  .item__description {
    grid-area: item__description;
  }

  .item__action {
    grid-area: item__action;
    justify-self: end;
    align-self: center;
    cursor: pointer;
  }

  @include media-breakpoint-up(sm) {
    grid-template-areas:
      "status status status status"
      "item__title item__title item__title item__action"
      "item__date item__description item__description item__action";
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0rem;
  }
}
