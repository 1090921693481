@import "assets/themes/helpers";

.questionaire {
  &__section {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    cursor: pointer;
    border-bottom: 1px solid $grey-59;

    &.is-active {
      color: $primary;
    }

    &:last-child {
      border: none;
    }
  }

  &__questions {
    margin-top: 2rem;
  }

  &__hint {
    display: flex;
    justify-content: center;
    align-items: baseline;

    & b {
      margin-right: 0.6rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
  }
}

.question {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $grey-59;

  &:last-child {
    border: none;
  }

  &__header {
    margin-bottom: 1rem;

    & p {
      margin-bottom: 0;
    }
  }
}

.workHours {
  &__single-question {
    border: none;
  }
}
