$overlay-transparency: .5;

.#{$prefix}loader-overlay {
  position: relative;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;

    &--primary {
      background-color: rgba($primary, $overlay-transparency);
    }

    &--secondary {
      background-color: rgba($secondary, $overlay-transparency);
    }

    &--dark {
      background-color: rgba($dark, $overlay-transparency);
    }

    &--light {
      background-color: rgba($light, $overlay-transparency);
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

}
