@font-face {
  font-family: "ft77";
  src: url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.eot");
  src: url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/f4460a25bc962308c6b4d98d72940252.svg#ft77") format("svg");
}

@font-face {
  font-family: "Gotham Pro";
  src: url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.eot");
  src: url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/056353a27c68233bab7e618c8b191d65.svg#Gotham Pro") format("svg");
}

@import "./variables";
@import "~@emanprague/basekit/dist/styles/style";
@import "./components";

.card {
  align-items: stretch;
}

.card-big .card-body {
  padding: 34px;
}
