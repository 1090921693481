.btn-rounded {
  border-radius: 100%;
  text-align: center;
  padding: 0;

  min-width: 40px;
  min-height: 40px;

  &.btn-sm {
    min-width: 30px;
    min-height: 30px;
  }
}
