@import "assets/themes/helpers.scss";

.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  margin: 1rem auto;

  > :not(nav) {
    width: 100%;
  }

  nav {
    margin: 1rem 0;
  }
}

.notifications__header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.notifications__list {
  padding: 2rem;
}

.notification-item {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  p {
    font-size: 0.875rem;
    color: $grey-47;
    margin: 0;
  }

  header {
    font-size: 1rem;
    color: $green-7;
    margin: 0.5rem 0;

    a {
      font-weight: bold;
      color: $green-7;
      text-decoration: underline;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $grey-89;
  }

  &.unread {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 3.2rem;
      left: -1rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}