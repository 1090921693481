@import "assets/themes/helpers.scss";

.credentials-company {
  .dheader__actions {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 61.9375rem) {
      * {
        flex: 1;
      }
    }
  }

  .paging-info {
    color: $grey-47;
  }

  .dheader__filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 61.9375rem) {
      * {
        flex: 1;
      }
    }

    input {
      border: none;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
      width: 15.625rem;
    }
  }

  .dheader__pagination {
    display: flex;
    align-items: center;
  }

  .paginationBar {
    display: flex;
    justify-content: center;
  }

  .avatar {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
  }
}
