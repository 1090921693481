// override bootstrap variables here

// UI Colors

$primary: #00949E;
$secondary: #004140;
$black: #000000;
$white: #FFFFFF;

$green-7: #00191B;
$green-15: #002B2E;
$green-24: #004045;
$green-35: #005C62;
$green-47: #007C84;
$green-59: #009DA8;
$green-69: #00BAC7;
$green-75: #00CCDA;
$green-80: #00DBEA;
$green-89: #BEE7EC;
$green-96: #E3F7FA;

$grey-7: #141616;
$grey-15: #242627;
$grey-24: #363A3A;
$grey-35: #4E5354;
$grey-47: #6A7172;
$grey-59: #888F91;
$grey-69: #A1AAAC;
$grey-75: #B0BBBC;
$grey-80: #BEC8CA;
$grey-89: #D8E1E3;
$grey-96: #F1F4F5;

$status-green-7: #081A01;
$status-green-15: #112C03;
$status-green-24: #1D4106;
$status-green-35: #2C5E0C;
$status-green-47: #3E7E14;
$status-green-59: #50A01C;
$status-green-69: #60BE23;
$status-green-75: #6AD027;
$status-green-80: #72DF2B;
$status-green-89: #81FB31;
$status-green-96: #E5F9DF;

$status-orange-7: #211201;
$status-orange-15: #372103;
$status-orange-24: #513306;
$status-orange-35: #724A0C;
$status-orange-47: #996414;
$status-orange-59: #C2801C;
$status-orange-69: #E59823;
$status-orange-75: #FAA728;
$status-orange-80: #FDB971;
$status-orange-89: #FED9BC;
$status-orange-96: #FEF1E8;

$status-red-7: #330401;
$status-red-15: #500903;
$status-red-24: #731206;
$status-red-35: #A11D0C;
$status-red-47: #D62A14;
$status-red-59: #FB5247;
$status-red-69: #FC8681;
$status-red-75: #FCA09D;
$status-red-80: #FDB4B2;
$status-red-89: #FED7D5;
$status-red-96: #FEF0F0;

$body-bg: $grey-96;
$body-color: $green-7;
$input-border-color: $grey-80;
$success: $status-green-96;

$font-family-sans-serif: "Gotham Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "ft77", $font-family-sans-serif;

// paddings
$navbar-padding-y: 0;
$navbar-padding-x: 0;

$input-btn-padding-y: 0.8125rem;
$input-btn-padding-x: 1.25rem;
$input-padding-x: 0.9375rem;
$input-padding-y: 0.6875rem;

// line height
$btn-line-height: 1rem;

// font-size
$input-btn-font-size: 0.875rem;
$input-font-size: 0.875rem;


// borders
$modal-header-border-color: none;

// border-radius
$border-radius: 2px;
$border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$badge-border-radius: 2px;

// font-weight
$btn-font-weight: bold;

// progress
$progress-height: 0.125rem;
$progress-bg: transparent;

// pagination
$pagination-border-color: transparent;
$pagination-color: $grey-47;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;
$pagination-hover-bg: transparent;
$pagination-hover-color: $primary;
$pagination-hover-border-color: transparent;

// card
$card-border-width: 0;

// breadcrumb
$breadcrumb-bg: transparent;
$breadcrumb-active-color: $black;
$breadcrumb-divider-color: $grey-59;
$breadcrumb-divider: quote("\27F6");

// tabs
$nav-tabs-link-active-color: $primary;
