.nav {
  &.nav-pills {
    border-radius: 2px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    font-size: 0.8125rem;
    line-height: 1rem;
    font-weight: 500;
    width: max-content;
    .nav-item {
      .nav-link {
        &:not(.active):not(.disabled) {
          color: $grey-47;
        }
      }
    }
  }
}
