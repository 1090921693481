.breadcrumb {
  padding: 0.5rem 0px;
  font-family: "ft77", $headings-font-family;
  .breadcrumb-item {
    a {
      color: $grey-47;
      text-decoration: underline;

      &:hover {
        color: $grey-35;
      }
    }
  }
}
