.modal__support {
  top: 50px;
  left: 64%;
  width: 35%;
  height: unset;

  & .modal-header > p {
    color: $grey-47;
    font-family: $headings-font-family;
    font-size: 1.1rem;
    font-weight: 400;
    max-width: 80%;
  }

  & .modal-body {
    padding: 1.25rem 1.5625rem !important;
    padding-top: 0 !important;

    & svg {
      color: $primary;
      margin-right: 0.5rem;
    }
  }
}
