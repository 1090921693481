.status {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  &.status-green {
    color: $status-green-59;

    &:before {
      background: $status-green-59;
    }
  }

  &.status-orange {
    color: $status-orange-69;

    &:before {
      background: $status-orange-69;
    }
  }

  &:before {
    display: inline-block;
    content: "\25CF";
    width: 0.625rem;
    height: 0.625rem;
    line-height: 0.625rem;
    font-size: 0.625rem;
    margin-right: 7px;
    border-radius: 50%;
  }
}
