@import "assets/themes/helpers";
@import "~@emanprague/veralinkkit/dist/styles/style";

.wrapper-spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-container {
  padding-top: 100px;
  position: relative;

  @include media-breakpoint-down(sm) {
    &.container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.toast-container {
  width: 100%;
  position: fixed;
  top: 5.75rem;
  left: 0;
  padding: 0px 1rem;
  z-index: 10;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar {
  width: 100vw;
}

