$light-gray: #d8e1e3;

.login {
  .divider {
    margin: 30px 0;
    &__line {
      background: $light-gray;
    }

    &__text {
      color: $light-gray;
    }
  }

  .instructions {
    margin: 50px 0 20px;

    & a {
      margin-left: 6px;
    }
  }

  .social-ico {
    width: 24px;
    height: 24px;
  }
}

.setup-password {
  padding: 50px 0;
}

.forgot-password {
  padding-bottom: 50px;

  & .title {
    margin: 20px 0;
  }

  .divider {
    margin-bottom: 10px;
    &__line {
      background: $light-gray;
    }

    &__text {
      color: $light-gray;
    }
  }
}
