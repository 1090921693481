@import "../../assets/themes/helpers";

.optional-text {
  color: $grey-47;
  font-size: 14px;
}

.worker-profile__layout {
  display: flex;

  @media (max-width: 48.125rem) {
    flex-direction: column;
  }

  .left-section {
    @media (min-width: 48.125rem) {
      width: 300px;
      margin-right: 30px;
    }
  }

  .right-section {
    margin-top: 1.25rem;
  }

  .greeting {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      h3 {
        padding: 0px 1rem;
      }
    }
  }
}

.strength {
  display: flex;
  margin: 1rem;

  .strength__texts {
    min-width: 140px;
  }

  .strength__score {
    width: 100%;
    display: flex;
    align-items: center;
    div {
      width: 100%;
      height: 8px;
      border-radius: 2px;
    }

    .score__wrapper {
      background: $grey-89;
      .score__value {
        background: $status-orange-69;
      }
    }
  }
}

.banner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $white;
  position: relative;

  @media print {
    display: none;
  }

  .banner__texts {
    position: absolute;
    padding: 1.5rem;
    width: 65%;
    left: 0;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    h4 {
      color: $primary;
    }
  }

  .image__wrapper {
    width: 45%;
    height: 196px;
    background: url("./../../assets/banner.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;

    @include media-breakpoint-down(md) {
      height: 300px;
    }

    div {
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

.skill__item {
  b {
    white-space: pre;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-height: 2rem;
  }
  height: 9rem;
  @include media-breakpoint-down(md) {
    height: initial;
  }
}
