@import "assets/themes/helpers.scss";

.contractor-portal__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;

  input {
    min-width: 20rem;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
    border: none;
  }

  .paging-info {
    margin-right: auto;
    color: $grey-47;
  }

  .dropdown {
    margin-bottom: 1rem;
  }
}

.contractor-portal__list {
  padding-bottom: 3rem;
  
  .contractor-search-card {
    margin-bottom: 2rem;
  }
}

.contractor-search-card {
  padding: 1rem;

  &__footer {
    display: flex;

    button {
      flex: 1;
      > svg {
        margin-right: 0.5rem;
      }
    }
  }

  > :not(:last-child) {
    margin-bottom: 1rem;
  }

  .badge {
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
  }

  p {
    color: $grey-59;
  }
}