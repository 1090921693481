@import "assets/themes/helpers.scss";

.credentials-search {
  &-card {
    padding: 1rem;
    margin: 1rem 0px;

    &__status {
      display: flex;
      justify-content: space-between;

      & .status {
        &__document {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.5rem 0;

          & p {
            margin-bottom: 0;
          }
        }

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 0.5rem 0.4rem;
          margin-right: 1rem;

          &.will-expire {
            background: $status-orange-96;

            & .ico {
              color: $status-orange-69;
            }
          }

          &.is-expired {
            background: $status-red-96;

            & .ico {
              color: $status-red-47;
            }
          }

          &.is-empty {
            background: $green-96;

            & .ico {
              color: $primary;
            }
          }

          &.is-valid {
            background: $status-green-96;

            & .ico {
              color: $status-green-59;
            }
          }

          & .ico {
            margin-left: 2px;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}
