@import "../../assets/themes/helpers";

.credentials {
  @include media-breakpoint-up(sm) {
    overflow: auto;
    max-height: 500px;
  }

  @media print {
    max-height: none;
  }

  &-header {
    margin-bottom: 2rem;
  }

  &-section {
    position: relative;
    margin-bottom: 0;

    &::after {
      position: absolute;
      content: "";
      height: 47px;
      bottom: 1px;
      right: 12px;
      width: 100%;
      height: 2rem;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    &__title {
      text-transform: uppercase;
    }
  }
}

.empty-credentials {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  &__hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 120px;
    height: 120px;
    background: $green-96;
    border-radius: 50%;
  }

  &__description {
    margin: 1.2rem 0;
    font-weight: bold;
    text-align: center;
    max-width: 70%;
    font-size: 1rem;
  }
}
