@import "assets/themes/helpers";

.experience-modifier__table {
  width: 100%;

  thead {
    tr {
      border-bottom: 1px solid $grey-89;
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $grey-89;
      }

      .table__actions {
        display: flex;
        justify-content: flex-end;

        span {
          color: $primary;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: $green-7;
          }
        }
      }
    }
  }
}
