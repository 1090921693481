$loader-size: 4rem !default;
$loader-color:  $primary !default;
$loader-bg-color: $gray-200 !default;
$loader-speed: 2s !default;


.#{$prefix}loader {
  border-width: ($spacer * .25);
  border-color: $loader-bg-color;
  border-top-color: $primary;
  border-radius: 50%;
  border-style: solid;
  width: $loader-size;
  height: $loader-size;
  animation: spin $loader-speed linear infinite;

  &--small {
    width: $loader-size / 1.5;
    height: $loader-size / 1.5;
  }

  &--big {
    width: $loader-size * 1.5;
    height: $loader-size * 1.5;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
