@import "assets/themes/helpers";

.safetyPrograms-status {
  &__icon {
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
  }

  &__red {
    color: $status-red-47;
  }
}

.safetyPrograms {
  &__header {
    margin-top: 2rem;
    font-weight: bold;
    font-size: 1.1rem;
  }

  &__footer {
    margin-top: 2rem;
  }

  &__table {
    thead {
      & tr {
        border-bottom: 1px solid $grey-89;
      }
    }
    tbody {
      & tr {
        &:not(:last-child) {
          border-bottom: 1px solid $grey-89;
        }
      }
    }
  }
}

.safety_action__container {
  display: flex;
  * {
    flex: 1;
  }
}
