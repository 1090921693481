@import "assets/themes/helpers";

.compliance {
  &-check {
    &-actions {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    &-row {
      margin: 2rem 0;

      & > .col {
        padding: 0;
      }

      & .subtitle {
        margin-top: 2rem;
        font-size: 1.1rem;
      }
    }
  }
}
