@import "assets/themes/helpers.scss";

.unread-badge {
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  background: $status-red-47;
  color: white;
  border-radius: 1rem;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
