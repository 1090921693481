@import "assets/themes/helpers";

.safety-metrics {
  &__footer {
    width: 100% !important;
    justify-content: space-between;
  }

  &__section {
    border-bottom: 1px solid $grey-89;
  }

  &__title {
    margin-top: 2.8rem;
  }
}

.edit-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;

  & > b {
    flex: 1 1;
    margin-bottom: 1rem;
  }

  &.threshold {
    max-width: 40%;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    & > b {
      flex: 0.5 1;
      margin-bottom: 1rem;
    }

    & > .form-group {
      margin-bottom: 0;
      flex: 1 1;
    }
  }

  &.experience {
    max-width: 60%;
    margin-top: 4rem;

    & > .form-group {
      margin-bottom: 0;
      flex: 0 1 100%;
    }
  }

  &.allocations {
    max-width: 60%;
    & > .form-group {
      margin-bottom: 0;
      flex: 0 1 30%;
    }
  }

  &.indicators {
    .indicators__append {
      position: absolute;
      top: 30%;
      right: 10%;
    }

    max-width: 100%;
    & > .form-group {
      margin-bottom: 0;
      flex: 0 1 15%;

      @include media-breakpoint-down(md) {
        flex: 0 1 30%;
      }
    }
  }

  & > hr {
    width: 8px;
    height: 1px;
    background: #000;
    margin: 1rem 0.5rem;
    margin-bottom: 2rem;
  }
}

.allocation-summary {
  margin-top: 2rem;

  &__total {
    color: $status-red-59;
    font-weight: bold;
    font-size: 1.1rem;

    &.is-good {
      color: $status-green-59;
    }
  }
}

.compliance__modal-service {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 2rem;

  .form-group {
    margin: 0;
  }

  > svg {
    cursor: pointer;
    margin-left: 1rem;
  }
}

.insurance {
  &__subtitle {
    font-size: 1.15rem;
    font-weight: bold;
    font-family: $font-family-sans-serif;
  }
}
