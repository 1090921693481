@import "assets/themes/helpers.scss";

.compliance {
  &-section {
    margin: 1rem 0;

    &__list {
      min-height: 30rem;

      .is-allocation {
        flex: 1 1 60% !important;

        & > b {
          margin-right: 0;
        }
      }

      & > .row {
        margin: 0 1rem;
        padding: 1.2rem 0;
        border-bottom: 1px solid $grey-89;

        &:last-child {
          border: none;
        }

        & > b {
          font-size: 1.1rem;
          margin-right: 1.8rem;
          margin-bottom: 0;
        }

        & > p {
          font-size: 1.1rem;
          margin-bottom: 0;
        }
      }
    }
  }
}
