$indicatorHeight: 4px;
.#{$prefix}passwordindicator {
  .level {
    position: relative;
    height: $indicatorHeight;
    min-height: $indicatorHeight;
    max-height: $indicatorHeight;
    background-color: $gray-200;

    &:not(:first-child):not(:last-child) {
      margin-left: 5px;
      margin-right: 5px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 0;
      transition: width 0.15s;
    }
    &.level-set::after {
      width: 100%;
    }
    &.level-1::after {
      background-color: $status-red-35;
      transition-delay: 0.3s;
    }
    &.level-set.level-1::after {
      transition-delay: 0s;
    }
    &.level-2::after {
      background-color: $status-orange-35;
      transition-delay: 0.15s;
    }
    &.level-3::after {
      background-color: $green-35;
      transition-delay: 0s;
    }
    &.level-set.level-3::after {
      transition-delay: 0.3s;
    }
  }
  .level-status {
    font-weight: 300;
    font-size: 1rem;
  }
}
