.card {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding-bottom: 0rem;
  }

  .card-footer {
    display: flex;
    background-color: $white;
    * {
      flex: 1;
    }
  }
}
