.skills {

  .skills__content {

    .skill__item {
      display: flex;
      flex-direction: column;
      padding: 1.625rem 1.5625rem;
      margin-bottom: 1.25rem;

      @media (max-width: 40.625rem) {
        margin-right: 0rem;
      }

      &.primary {
        background-color: $primary;
        color: $white;
      }

      &.secondary {
        background-color: $grey-96;
      }

      label {
        font-weight: 700;
        font-size: 0.875rem;
      }
      b {
        font-weight: 700;
        font-size: 1.25rem;
      }
      span {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }

  section {
    div {
      .badge {
        margin: 0px 0.3125rem 0.3125rem 0px;
      }
    }
  }
}

