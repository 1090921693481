@import "assets/themes/helpers.scss";

.company-info__card {
  margin-bottom: 2rem;

  h4 {
    text-transform: uppercase;
  }

  p {
    color: $green-7;
    font-size: 0.875rem;
    font-weight: bold;

    > span {
      font-weight: normal;
    }
  }

  span {
    color: $grey-47;
    font-size: 1rem;
    font-weight: 500;
  }

  // services badges
  .badge {
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
  }
}

.company-info__list {
  > div {
    display: flex;
    align-items: center;
    margin: 0;

    &:first-child > p {
      margin-top: 0;
    }

    > p {
      flex: 1;
      color: $green-7;
      font-size: 0.875rem;
      font-weight: bold;
      margin: 1rem 0;

      &:last-child {
        flex: 1;
        font-weight: normal;
      }
    }
  }

  > :not(:last-child) {
    border-bottom: 1px solid $grey-89;
  }
}

.company-info__location {
  margin-bottom: 1rem;

  h5 {
    font-weight: normal;
    color: $green-7;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-weight: normal;
    font-size: 1rem;
    color: $grey-47;
    margin: 0;
  }
}

.company-info__contact {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;

  h6 {
    font-weight: normal;
    font-size: 1rem;
    color: $green-7;
    margin: 0;
  }

  span {
    font-weight: normal;
    font-size: 0.875rem;
    color: $grey-47;
    margin-bottom: 1rem;
  }

  p {
    font-weight: normal;
    font-size: 0.875rem;
    color: $green-7;
    margin: 0.2rem;

    > svg {
      margin-right: 0.5rem;
    }
  }
}

.company-info__contacts-group {
  border: 1px solid $grey-89;
  border-radius: 2px;
  margin-bottom: 1rem;

  /* header */
  > :nth-child(1) {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 1rem;

    h5 {
      font-weight: normal;
      letter-spacing: 0.05em;
      color: $green-7;
      margin: 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      font-size: 0.75rem;
      height: 1.2rem;
      color: $green-7;
      background: $grey-89;
      border-radius: 0.75rem;
      padding: 0.1rem 0.5rem;
      margin: 0 0.5rem;
    }

    svg {
      margin-left: auto;
    }
  }

  /* content */
  > :nth-child(2) {
    > * {
      margin-left: 1rem;
      padding: 1rem 0;
    }

    > *:not(:last-child) {
      border-bottom: 1px solid $grey-89;
    }
  }
}

.company-info {
  &__policies {
    margin-top: 1.2rem;

    &-header {
      flex-direction: column;
      align-items: center;

      &__actions {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1.2rem;

        & .btn-dark {
          max-height: 2.8rem;
          background: $grey-96;
          border-color: $grey-96;
          color: $grey-47;
        }
      }
    }
  }

  &__policy {
    margin-top: 2rem;
    &-upload {
      padding: 0 2.8rem 0 15px;

      & > .attachment {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &-action {
      margin-top: 1rem;
      margin-left: 0;
      margin-bottom: 2rem;
    }

    &-item {
      margin-top: 1rem;
      text-align: left;
      border-bottom: 1px solid $grey-89;

      & .company-info__worktype-item {
        margin-left: 1px;
      }
    }
  }

  &__worktype-item {
    align-items: center;
    justify-content: center;

    & .form-group {
      flex: 1 1 80%;
    }

    & svg {
      margin-bottom: 1rem;
      margin-left: 1.5rem;
    }
  }
}
