@import "../assets/themes/helpers";

.infoTooltipIcon {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
}

.optionalText {
  color: $grey-47;
  font-size: 14px;
  margin-left: 0.5rem;
}
