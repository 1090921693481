@import "~@emanprague/veralinkkit/dist/styles/variables";

.status-profile {
  .user__avatar {
    object-fit: contain;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;

    p {
      margin: 0.5rem;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0.5rem 2rem;
    min-width: 20rem;
  }

  .dropdown-item {
    padding: 1.5rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-89;
    }

    &:hover {
      color: $primary;
      background: none;
    }
  }
}

.navbar-status {
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin: 0;

  .dropdown-toggle {
    padding: 0.5rem;
  }

  .unread-badge {
    position: absolute;
    top: 22%;
    left: 50%;
  }
}
