.signup {
  h1,
  .subtitle {
    margin: 30px 0;
  }

  p {
    font-size: 20px;
  }

  .select-footer {
    margin-top: 2%;
    font-size: 14px;
    & span {
      margin-right: 6px;
    }
  }

  .card {
    margin: 16px;
    display: flex;
    max-width: 340px;
    height: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      margin-top: 20px;
      width: 100px;
      height: 100px;
    }

    &__role-description {
      margin: 12px;
      font-size: 16px;
    }

    &__cta {
      width: 80%;
      margin: 0 30px 30px 30px;
    }
  }

  .hero {
    width: 80px;
    height: 80px;
  }
}

.signup-form {
  & .form-label {
    font-size: 14px;
    line-height: 17px;
  }

  .terms {
    margin-top: 30px;
    text-align: center;
  }
}

.signup-success {
  background: white;
  padding: 40px;

  .kit__loader-overlay {
    min-height: 3rem;
  }

  & .location {
    &__title {
      margin-top: 2rem;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  & .text-area {
    height: 100px;
    resize: none;
  }

  & form {
    padding-bottom: 80px;
  }

  .form-group {
    & .form-label {
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }

    .add-btn {
      margin: 10px 0;

      &__icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .full-size {
    width: 100%;
  }

  .subtitle {
    text-align: center;
  }
  .description {
    margin: 30px 0;
  }
  .card {
    &__hero {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 120px;
      height: 120px;
      background: #e3f7fa;
      border-radius: 50%;
    }

    &__hero-icon {
      width: 100px;
      height: 100px;
    }

    &__btn {
      max-width: 120px;
    }
  }
}

.signup-confirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.signCheck {
  padding-bottom: 1rem;
  .terms {
    margin-bottom: 0;
  }
  input {
    position: absolute;
    top: calc(50% - (12px / 2));

    &.is-invalid {
      top: calc(50% - (12px / 2) - 12px);
    }
  }
}
